<template>
  <div class="apply-out-box">
    <div class="apply-box-white">
      <div class="apply-box">
        <div class="a-top-box">
          <div class="a-left-box">
          <span class="headline-box">
            <p class="headline">活动简介：</p>
            <p class="content">{{ data.description }}</p>
          </span>
            <span class="headline-box">
            <p class="headline">投票时间：</p>
            <p class="content">{{ getDate(data.vodeStartDate) }} 至 {{ getDate(data.vodeEndDate) }}</p>
          </span>
          </div>
          <div class="right-box">
            <div class="right-first-box">
      <span class="headline-box">
            <p class="headline">作品类型：</p>
            <p class="content">   {{ data.activeType == 'IMG' ? '图片' : '视频' }}</p>
          </span>
              <span class="headline-box">
            <p class="headline">活动状态：</p>
            <p class="state-text" :style="{color:getStatusColor(this.data.state)}">
              {{ getStatus(this.data.state) }}
            </p>
          </span>
            </div>

          </div>

        </div>
        <div class="rule-box headline-box"  v-show="getStatus(this.data.state)==='作品投票中' || getStatus(this.data.state)==='活动已结束'">
          <p class="headline">投票规则：</p>
          <p class="content">{{ data.vodeRule == 'USER_VOTE'?'单用户只允许投一票':'单用户每日可投一票' }}</p>
        </div>



        <div class="apply-top-box" v-show="contentVisible">
          <div class="main-content" v-html="data.content"/>
        </div>

      </div>
    </div>

    <div class="pulldown-box" @click="showContent" v-show="!collecting">
      <img src="/images/activity/pulldown.png" class="pulldown"/>
      <div class="down-img-box">
        <img :src="!contentVisible?'/images/activity/donw-img.png':'/images/activity/up-img.png' " class="down-img"/>
      </div>
    </div>


  </div>
  <div class="apply-bottom-box" v-show="collecting">
    <div class="main-content" v-html="data.content"/>
  </div>
  <div class="a-bottom-box">
    <div style="width: 12rem;">
      <div v-show="getStatus(this.data.state)==='作品征集结束' && list.length>0">
        <ActivityEnd :data="list"/>
      </div>
      <div v-show="getStatus(this.data.state)==='作品投票中' && list.length>0">
        <ActivityDoing :data="list"/>
      </div>
      <div v-show="getStatus(this.data.state)==='活动已结束' && list.length>0">
        <ActivityRank :data="list"/>
      </div>
    </div>
  </div>

</template>

<script>
import moment from 'moment'
import {ref} from 'vue'
import api from '@/api'
import ActivityEnd from '@/components/activitydetail/activity-end'
import ActivityDoing from '@/components/activitydetail/activity-doing'
import ActivityRank from '@/components/activitydetail/activity-rank'
//征集活动
export default {
  name: 'collect-activity',
  components: {
    ActivityEnd,
    ActivityDoing,
    ActivityRank
  },
  props: {
    data: {
      type: Object,
      default: null
    },

  },
  computed: {

    end () {
      return this.data.state == 'SIGN_OVER'//投票已结束
    },

  },

  methods: {
    getDate (date) {
      return moment(date).format('YYYY-MM-DD hh:mm')
    },
    getStatus (s) {
      let dic = {
        'WORK_START': '作品征集中',
        'WORK_OVER': '作品征集结束',
        'VOTE_START': '作品投票中',
        'SIGN_OVER': '活动已结束'
      }
      return dic[s] || '';
      // return '作品征集结束'
      // return '作品投票中'
      // return '活动已结束'
    },
    getStatusColor(s){
      let dic = {
        'WORK_START': '#01BB6C',
        'WORK_OVER': '#D6D6D6',
        'VOTE_START': '#FFBF5E',
        'SIGN_OVER': '#D6D6D6'
      }
      return dic[s] || '#D6D6D6';
    }
  },
  setup (props, ctx) {
    const contentVisible = ref(false)
    const total = ref(0)
    const list = ref([])
    const showContent = () => {
      contentVisible.value = !contentVisible.value
    }


    const getData = async () => {
      let res = await api.activityCollection({activeId: props.data.id??'', pageNum: 1, pageSize: 10, orderFlag: true})
      // console.log('---------', res)
      // list.value = res.records;
      // list.value = [
      //   {
      //     'url': 'https://t2.oss-chengdu1.yunzhicloud.com/town/image/2022/0523/efc52b952af94c9eb40c49a3ac01b4ed.jpg',
      //     'title': '活动名称',
      //   },
      //   {
      //     'url': 'https://t2.oss-chengdu1.yunzhicloud.com/town/video/2021/0316/ca30fd2a1ee44c2e83db36937817bec2.mp4',
      //     'title': '活动名称视频',
      //   }
      // ]
      total.value = res.total
    }

    getData()
    return {
      contentVisible,
      showContent,
      list,
      total
    }
  }

}
</script>

<style lang="scss" scoped>
.apply-out-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  .apply-box-white {
    width: 100%;
    background: #ffffff;
  }
}

.apply-box {
  background: #ffffff;

  //height: 1.13rem;

  display: flex;
  align-items: center;
  flex-direction: column;

  .a-top-box {
    display: flex;
    width: 12rem;
    justify-content: space-between;

  }

  .pricebg {
    width: 1.16rem;
    height: 0.65rem;
  }

  .rule-box {
    width: 12rem
  }

  .headline-box {
    display: flex;
    margin: 0.06rem 0;

    .headline {
      color: #999999;
      font-size: 0.16rem;
    }

    .content {
      color: #333333;
      font-size: 0.16rem;
    }

    .state-text {
      color: #999999;
      font-weight: 700;
      font-size: 0.16rem;
    }
  }

  .right-box {
    display: flex;
  }

  .price-box {
    position: relative;
    display: flex;
    margin-left: 0.2rem;

    .price-cover-box {
      position: absolute;
      width: 1.16rem;
      height: 0.65rem;

      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;


      .price-text {
        color: #EA2323;
        font-width: 700;
        font-size: 0.3rem;
        margin-top: -0.05rem;
      }

      .line {
        width: 0.83rem;
        height: 0.01rem;
        margin-top: -0.05rem;
        border-bottom: 0.01rem dashed #EA2323;
      }

      .line-pass {
        width: 0.83rem;
        height: 0.01rem;
        margin-top: -0.05rem;
        border-bottom: 0.01rem dashed #CCCCCC;
      }

      .price-desc {
        color: #FB8787;
        font-size: 0.12rem;
      }
    }
  }

}

.apply-top-box {
  display: flex;
  width: 100%;
  justify-content: center;
  background: #ffffff;
}

.apply-bottom-box {

  display: flex;
  width: 100%;
  justify-content: center;
}

.main-content {
  word-break: break-all;
  margin-top: 0.5rem;
  width: 12rem;

  & /deep/ img {
    width: 100%;
    max-width: 100%;
  }
}

.pulldown-box {
  margin-top: -0.01rem;
  display: flex;
  position: relative;
  cursor: pointer;

}

.pulldown {
  width: 0.65rem;
  height: 0.21rem;
  margin: 0 0.1rem 0.1rem 0.1rem;
}

.down-img-box {
  width: 0.65rem;
  height: 0.21rem;
  position: absolute;
  margin: 0 0.1rem 0.1rem 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .down-img {
    width: 0.165rem;
    height: 0.08rem;
  }
}


.a-bottom-box {
  width: 100%;
  align-items: center;
  flex-direction: column;
  display: flex
}


</style>
