<template>
  <div class="top-box">
    作品征集已结束，投稿阶段即将开启
  </div>
  <ul class="content-list">

    <li v-for="(value,index) in data" :key="index" class="list-item">
      <div class="item-box">
        <video v-if="$fileType.isVideo(value.url)" :src="value.url"  class="video" />
        <img :src="value.url" class="img" v-else>
        <span class="title">{{value.title}}</span>
      </div>

    </li>
  </ul>
</template>

<script>
export default {
  name: 'activity-end',
  props:{
    data:Array,
    default:()=>[]
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  text-align: left;
  font-width: 700;
  color: #999999;
  display: flex;
  margin-top: 0.37rem;
  width: 12rem;
  font-size: 0.2rem;
}

.content-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 0.4rem;
  width: 12rem;


  .list-item {
    position: relative;
    margin-bottom: 0.4rem;
    transition: all 300ms;

    &:not(:nth-of-type(4n)) {
      margin-right: 0.18rem;
    }
  }

  .item-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img {
    width: 2.86rem;
    height: 1.79rem;
  }
  .video{
    width: 2.86rem;
    height: 1.79rem;
    object-fit: cover;
  }

  .title {
    width: 2.86rem;
    font-size: 0.18rem;
    margin-top: 0.15rem;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    overflow : hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-align: center;
  }
}
</style>
