<template>

  <ul class="ul">
    <li class="list">
      <div class="li-box">
          <span class="id" style="flex: 1">
          编号
          </span>
        <span class="id" style="flex: 1">
           作品
          </span>
        <span class="id" style="flex: 1">
            总票数
          </span>
        <span class="id" style="flex: 1">
            排名
          </span>
      </div>
    </li>
    <li v-for="(value,index) in data" class="list" :key="index">
      <div class="li-box" >
          <span class="id nb-box" style="flex: 1">
            {{value.id}}
          </span>
        <span style="flex: 1" class="nb-box">
            <video v-if="$fileType.isVideo(value.url)" :src="value.url"  class="video" />
             <img :src="value.url" class="img" v-else>
          </span>
        <span class="num nb-box" style="flex: 1">
            {{data.voteNums || 0}}
          </span>
        <span style="flex: 1" class="nb-box">
             <img :src="`/images/activity/rank-${index+1}.png`" class="rank-img"/>
          </span>
      </div>
    </li>
  </ul>

</template>

<script>
export default {
  name: 'activity-rank',
  props: {
    data: {
      type: Array,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>


.ul {
  display: flex;
  flex-direction: column;
  width: 12rem;
}

.list {
  height: 1.07rem;
  //background: red;
  display: flex;
  align-items: center;

  border-bottom: 0.02rem solid #DBDBDB;
}

.nb-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.li-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}
.img {
  width: 1.32rem;
  height: 0.82rem;
}
.video {
  width: 1.32rem;
  height: 0.82rem;
  object-fit: cover;
}
.id {
  font-size: 0.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.num {
  color: #FF663F;
  font-size: 0.18rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rank-img{
  width: 0.62rem;
  height: 0.63rem;
}
</style>
