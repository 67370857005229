<template>
  <div class="apply-out-box">
    <div class="apply-box-white">
      <div class="apply-box">
        <div class="a-top-box">
          <div class="a-left-box">
          <span class="headline-box">
            <p class="headline">活动简介：</p>
            <p class="content">{{ data.description }}</p>
          </span>
            <span class="headline-box">
            <p class="headline">报名时间：</p>
            <p class="content">{{ getDate(data.signStartDate) }} 至 {{ getDate(data.signEndDate) }}</p>
          </span>
          </div>
          <div class="right-box">
            <div class="right-first-box">
      <span class="headline-box">
            <p class="headline">报名类型：</p>
            <p class="content">   {{ data.activeType == 'FREE' ? '免费报名' : '收费报名' }}</p>
          </span>
              <span class="headline-box">
            <p class="headline">活动状态：</p>
            <p class="state-text" :style="notEnd?{color:'#FF663F'}:{color:'#999999'}">
              {{ notEnd ? '火热报名中' : '报名已结束' }}
            </p>
          </span>
            </div>
            <div class="price-box" v-show="data.activeType!=='FREE'">
              <img :src="notEnd?'/images/activity/pricebg.png':'/images/activity/pricebg-pass.png'" class="pricebg">
              <div class="price-cover-box">
                <p class="price-text" :style="notEnd?{color:'#EA2323'}:{color:'#CCCCCC'}">{{ data.money || 0 }}</p>
                <span :class="notEnd?'line':'line-pass'"/>
                <p class="price-desc" :style="notEnd?{color:'#EA2323'}:{color:'#CCCCCC'}">报名费用(元)</p>
              </div>
            </div>
          </div>
        </div>

        <div class="apply-top-box" v-show="!notEnd && contentVisible">
          <div class="main-content" v-html="data.content"/>
        </div>

      </div>
    </div>

    <div class="pulldown-box" @click="showContent" v-show="!notEnd">
      <img src="/images/activity/pulldown.png" class="pulldown"/>
      <div class="down-img-box">
        <img :src="!contentVisible?'/images/activity/donw-img.png':'/images/activity/up-img.png' " class="down-img"/>
      </div>
    </div>

    <div class="apply-list-box" v-show="!notEnd">
      <p class="num-text">已有{{total}}人报名成功</p>
      <ul v-show="list.length>=0">
        <li>
          <div class="list-item-box">
            <div class="list-item">
              <p class="list-title-text">昵称</p>
            </div>
            <div class="list-item">
              <p class="list-title-text">联系方式</p>
            </div>
            <div class="list-item">
              <p class="list-title-text">报名时间</p>
            </div>
          </div>
        </li>
        <li v-for="(item,index) in list" :key="index">
          <div class="list-item-box">
              <div class="list-item">
                <p class="list-item-text">{{ item.userName }}</p>
              </div>
            <div class="list-item">
              <p class="list-item-text">{{ item.tel }}</p>
            </div>
            <div class="list-item">
              <p class="list-item-text">{{ getDate(item.createDate) }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>

  </div>
  <div class="apply-bottom-box" v-show="notEnd">
    <div class="main-content" v-html="data.content"/>
  </div>


</template>

<script>
import moment from 'moment'
import {reactive, ref} from 'vue'
import api from '@/api'

//报名活动
export default {
  name: 'apply-activity',

  props: {
    data: {
      type: Object,
      default: null
    },

  },
  computed: {
    notEnd () {
      return this.data.state == 'SIGN_START'

    },

  },

  methods: {
    getDate (date) {
      return moment(date).format('YYYY-MM-DD hh:mm')
    }
  },
  setup (props,ctx) {
    const contentVisible = ref(false)
    const total = ref(0);
    const list = ref([]);
    const showContent = () => {
      contentVisible.value = !contentVisible.value
    }

    console.log('props---',props.data);

    const getData = async () => {
      let res = await  api.activityApplyInfo({activeId:props.data.id??'',pageNum:1,pageSize:10})
      // console.log('---------',res);
      list.value = res.records || [];
      // list.value = [
      //   {'createDate':1653875548000,'userName':'王小明','tel':'13881912345'}
      // ];
      // total.value = res.total;
    }

  getData();
    return {
      contentVisible,
      showContent,
      list,
      total
    }
  }

}
</script>

<style lang="scss" scoped>
.apply-out-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  .apply-box-white {
    width: 100%;
    background: #ffffff;
  }
}

.apply-box {
  background: #ffffff;

  //height: 1.13rem;

  display: flex;
  align-items: center;
  flex-direction: column;

  .a-top-box {
    display: flex;
    width: 12rem;
    justify-content: space-between;

  }

  .pricebg {
    width: 1.16rem;
    height: 0.65rem;
  }

  .headline-box {
    display: flex;
    margin: 0.06rem 0;

    .headline {
      color: #999999;
      font-size: 0.16rem;
    }

    .content {
      color: #333333;
      font-size: 0.16rem;
    }

    .state-text {
      color: #999999;
      font-weight: 700;
      font-size: 0.16rem;
    }
  }

  .right-box {
    display: flex;
  }

  .price-box {
    position: relative;
    display: flex;
    margin-left: 0.2rem;

    .price-cover-box {
      position: absolute;
      width: 1.16rem;
      height: 0.65rem;

      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;


      .price-text {
        color: #EA2323;
        font-width: 700;
        font-size: 0.3rem;
        margin-top: -0.05rem;
      }

      .line {
        width: 0.83rem;
        height: 0.01rem;
        margin-top: -0.05rem;
        border-bottom: 0.01rem dashed #EA2323;
      }

      .line-pass {
        width: 0.83rem;
        height: 0.01rem;
        margin-top: -0.05rem;
        border-bottom: 0.01rem dashed #CCCCCC;
      }

      .price-desc {
        color: #FB8787;
        font-size: 0.12rem;
      }
    }
  }

}

.apply-top-box {
  display: flex;
  width: 100%;
  justify-content: center;
  background: #ffffff;
}

.apply-bottom-box {

  display: flex;
  width: 100%;
  justify-content: center;
}

.main-content {
  word-break: break-all;
  margin-top: 0.5rem;
  width: 12rem;

  & /deep/ img {
    width: 100%;
    max-width: 100%;
  }
}

.pulldown-box {
  margin-top: -0.01rem;
  display: flex;
  position: relative;
  cursor: pointer;

}

.pulldown {
  width: 0.65rem;
  height: 0.21rem;
  margin: 0 0.1rem 0.1rem 0.1rem;
}

.down-img-box {
  width: 0.65rem;
  height: 0.21rem;
  position: absolute;
  margin: 0 0.1rem 0.1rem 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .down-img {
    width: 0.165rem;
    height: 0.08rem;
  }
}

.apply-list-box {
  height: 3rem;
  width: 12rem;

  .num-text {
    color: #999999;
    font-size: 0.22rem;
  }
  .list-item-box {
    display: flex;
    justify-content: center;
    border-bottom: 0.01rem solid #333333;
    height: 0.7rem;
      .list-item {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        &:first-child {
          justify-content: flex-start;
        }


      }
    .list-title-text{
      color:#999999;
      font-size: 0.18rem;
    }
    .list-item-text{
      color:#333333;
      font-size:0.18rem
    }
  }
}

</style>
