<template>
  <div class="wrap">
    <div class="banner-box">
      <img src="/images/activity/title.png" class="title"/>
    </div>
    <div  class="top-box" :style="data.type !== 'VIEW_ACTIVE'?{'background':'white'}:{}">
      <div class="bottom-box" >
        <NewsHeader :dataSource="data"/>
      </div>

    </div>

<!--报名活动-->
    <div v-if="data.type === 'SIGN_ACTIVE'" style="width: 100%">
      <ApplyActivity :data="data" />
    </div>
<!--    征集投票活动-->
    <div v-if="data.type === 'VOTE_ACTIVE'" style="width: 100%">
      <CollectActivity :data="data" />
    </div>
<!--    抽奖活动-->
    <div v-if="data.type === 'DRAW_ACTIVE'" style="width: 100%">
      <SweepstakesActivity :data="data"/>
    </div>
<!--展示活动-->
    <div v-if="data.type === 'VIEW_ACTIVE'">
      <div class="apply-top-box" v-show="true">
        <div class="main-content" v-html="data.content"/>
      </div>
    </div>


    <div class="btn" @click="showCode(true)">
      {{buttonText}}
    </div>
    <a-modal v-model:visible="codeVisible" :footer="null" :bodyStyle="{ padding: 0 }">
      <div class="model-box">
        <img src="/images/activity/townqrcode.png" class="qrcode-img"/>
        <span class="text" style="margin-bottom: 0.14rem">微信扫描二维码参加活动</span>
      </div>

    </a-modal>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue'

import NewsHeader from '@/components/common/newsheader'
import api from '@/api'
import {useRoute} from 'vue-router'
import ApplyActivity from '@/components/activity/apply-activity'
import CollectActivity from '@/components/activity/collect-activity'
import SweepstakesActivity from '@/components/activity/sweepstakes-activity'
export default {
  name: 'detail',
  components:{
    NewsHeader,
    ApplyActivity,
    CollectActivity,
    SweepstakesActivity
  },
  setup (props) {
    let data = ref({})
    let buttonText = ref('');

    const route = useRoute()
    let id = route.query?.id;

    const contentVisible = ref(false)
    const codeVisible = ref(false)
    const showCode = (status) => {
      codeVisible.value = status
    }
    const status = '已结束';
    const showContent = () =>{
      contentVisible.value = !contentVisible.value;
    }
    const getData = async () => {
      let res = await api.activityDetail({id:id});
      data.value = res;

      if (res.type == 'SIGN_ACTIVE'){//报名活动
        if (res.state == 'SIGN_START'){
          buttonText.value = '去报名';
        }else  {
          buttonText.value = '查看报名详情'
        }
      } else if (res.type == 'VOTE_ACTIVE') {//投票活动
        if (res.state == 'WORK_START'){
          buttonText.value = '去投稿';
        }else if (res.state == 'WORK_OVER' || res.state == 'VOTE_START'){
          buttonText.value = '去投票';
        }else  if (res.state == 'SIGN_OVER'){
          buttonText.value = '查看详情'
        }
      } else if (res.type == 'DRAW_ACTIVE') {//抽奖活动
        if (res.state == 'SIGN_OVER'){
          buttonText.value = '查看抽奖结果'
        }else  {
          buttonText.value = '去抽奖'
        }

      } else if (res.type == 'VIEW_ACTIVE'){//展示活动
        buttonText.value = '查看活动详情'

      }

    }

    const read =  ()=>{
      api.activityRead({id:id})
    }

    onMounted(()=>{
        read();
    })


    getData();
    return {

      codeVisible,
      showCode,
      status,
      contentVisible,
      showContent,
      data,
      buttonText
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #E5E5E5;
  padding-bottom: 0.2rem;
}

.banner-box {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7.36rem;
  width: 100%;
  background: url("/images/activity/banner.png") no-repeat;
  background-size: 100% 100%;
}

.top-box {
  width: 100%;
  background: transparent;
  justify-content: center;
  display: flex
}
.bottom-box {
  width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.6rem;
  //margin-bottom: 0.3rem;
}

.pulldown-box {
  margin-top: -0.01rem;
  display: flex;
  position: relative;
  cursor: pointer;
}

.pulldown {
  width: 0.65rem;
  height: 0.21rem;
  margin: 0 0.1rem 0.1rem 0.1rem;
}
.down-img-box {
  width: 0.65rem;
  height: 0.21rem;
  position: absolute;
  margin: 0 0.1rem 0.1rem 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .down-img {
    width: 0.165rem;
    height: 0.08rem;
  }
}



.text {
  font-size: 0.16rem;
  color: #666666
}
.desc {
  @include text-moreLine(1)

}


.main-content {
  word-break: break-all;
  margin-top: 0.5rem;
  width: 12rem;
  background: transparent;
  & /deep/ img {
    width: 100%;
    max-width: 100%;
  }
}
.btn {
  background: #01398D;
  color: #FFFFFF;
  font-size: 0.22rem;
  padding: 0.13rem 0.58rem;
  cursor: pointer;
  margin: 0.6rem 0;
  //margin-bottom: 1.08rem;
}

.model-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  .qrcode-img {
    width: 1.44rem;
    height: 1.44rem;
    margin: 0.2rem 0;
  }
}
.apply-top-box {
  display: flex;
  width: 100%;
  justify-content: center;

  padding-bottom: 0.4rem;
}



</style>
