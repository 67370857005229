<template>
  <div class="top-box">
    作品征集已结束，投稿阶段即将开启
  </div>
  <ul class="content-list">

    <li v-for="(value,index) in data" :key="index" class="list-item">
      <div>
        <div style="  height: 1.79rem; ">
        <video v-if="$fileType.isVideo(value.url)" :src="value.url"  class="video" />
        <img :src="value.url" class="img" v-else>
        </div>
        <div class="bottom-box">
          <span class="title">{{value.title}}</span>
          <span class="support">
            <img src="/images/activity/support.png" style="width: 0.18rem;height: 0.17rem"/>
            <span>{{ value.voteNums || 0 }}</span>
          </span>
        </div>
<!--        <a-progress :percent="70" :show-info="false" :strokeColor="'#FF663F'" trailColor="#FED7CD"-->

<!--        />-->
      </div>

    </li>
  </ul>
</template>

<script>
export default {
  name: 'activity-doing',
  props:{
    data:Array
  },
}
</script>

<style lang="scss" scoped>
.top-box {
  text-align: left;
  font-width: 700;
  color: #999999;
  display: flex;
  margin-top: 0.37rem;
  width: 12rem;
}

.content-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 0.4rem;
  width: 12rem;



  .list-item {
    position: relative;
    margin-bottom: 0.4rem;
    transition: all 300ms;

    &:not(:nth-of-type(4n)) {
      margin-right: 0.18rem;
    }
  }



  .img {
    width: 2.86rem;
    height: 1.79rem;
    object-fit: cover;
  }
  .video{
    width: 2.86rem;
    height: 1.79rem;
    object-fit: cover;

  }
  .bottom-box {
    display: flex;
    width: 2.86rem;
    justify-content: space-between;
    align-items: center;
    height: 0.5rem;
    justify-content: center;

  }
  .support {
    font-size: 0.18rem;
    color: #FF663F;

    display: flex;
    align-items: center;
  }
  .title {
    width: 2.86rem;
    font-size: 0.18rem;

    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    overflow : hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;


  }

  ///deep/ .ant-progress-inner{
  //  border-radius: 0;
  //  height: 0.065rem;
  //
  //}
  ///deep/ .ant-progress-bg {
  //  border-radius: 0 !important;
  //  height: 0.065rem;
  //}


}
</style>
